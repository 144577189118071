import { useMemo } from 'react'

import { UnwrapArray } from '@/shared/lib/types'
import { FormElementType, LikertFeedback, PersonFeedbacks } from '@monorepo/functions/src/types/feedback'

import { adaptPersonFeedbacksToFlatFeedbacks } from '../../entities/feedback/model/flatFeedback/adaptFromPersonFeedbacks'
import { FlatFeedback } from '../../entities/feedback/model/flatFeedback/model'
import { isLikert, isYesNo } from '../../entities/feedback/model/model'
import { Filter } from './model'

const getLikertOptions = (feedbacks: LikertFeedback[]) => {
    const uniqueValues = Array.from(new Set(feedbacks.map(f => f.answer.value)))
    return uniqueValues
        .sort((a, b) => a - b)
        .map(value => ({
            value: value.toString(),
            label: value.toString(),
            description:
                value === 1
                    ? 'Muy en desacuerdo'
                    : value === 2
                      ? 'En desacuerdo'
                      : value === 3
                        ? 'Neutral'
                        : value === 4
                          ? 'De acuerdo'
                          : 'Muy de acuerdo',
        }))
}

const YES_NO_OPTIONS = [
    { value: 'true', label: 'Sí' },
    { value: 'false', label: 'No' },
]

const getCountryName = (countryCode: string, locale = 'es-ES') => {
    try {
        const displayNames = new Intl.DisplayNames([locale], { type: 'region' })
        return displayNames.of(countryCode) ?? null
    } catch (err) {
        return null
    }
}

export const useAvailableFilters = (feedbacks: PersonFeedbacks[]): Filter[] => {
    const flatFeedbacks = useMemo(() => adaptPersonFeedbacksToFlatFeedbacks(feedbacks), [feedbacks])

    const questionFilters: Filter[] = useMemo(() => {
        // Group feedbacks by question title and type
        const feedbacksByQuestion = flatFeedbacks.reduce(
            (acc, feedback) => {
                const key = feedback.question.title
                if (!acc[key]) {
                    acc[key] = []
                }
                acc[key].push(feedback)
                return acc
            },
            {} as Record<string, FlatFeedback[]>,
        )

        return Object.entries(feedbacksByQuestion)
            .map(([title, questionFeedbacks]) => {
                const firstFeedback = questionFeedbacks[0]
                const baseQuestion = {
                    title,
                    type: firstFeedback.question.type as FormElementType,
                    departments: firstFeedback.question.departments,
                    categories: firstFeedback.question.categories,
                }

                const filter: Filter = {
                    title: baseQuestion.title,
                    type: 'QUESTION',
                    options: [],
                }

                // Special handling for Likert and YesNo since they need custom options
                if (isLikert(firstFeedback)) {
                    filter.options = getLikertOptions(questionFeedbacks as LikertFeedback[])
                } else if (isYesNo(firstFeedback)) {
                    filter.options = YES_NO_OPTIONS
                    // For other types, we can use the options directly from the question
                } else if ('options' in firstFeedback.question) {
                    filter.options = firstFeedback.question.options
                }

                return filter
            })
            .filter(question => question.options && question.options.length > 0)
    }, [flatFeedbacks])

    const availableCountriesMap = feedbacks.reduce(
        (acc, feedback) => {
            const key = feedback.location?.country
            if (!key) return acc

            if (!acc[key]) {
                const countryName = getCountryName(key)
                if (countryName) acc[key] = { label: countryName, value: key }
            }
            return acc
        },
        {} as Record<string, UnwrapArray<Filter['options']>>,
    )
    const countriesOptions = Object.values(availableCountriesMap)

    const countryFilter: Filter = {
        title: 'País',
        type: 'COUNTRY',
        options: countriesOptions,
    }

    return [countryFilter, ...questionFilters]
}
