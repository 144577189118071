import { createContext, useContext, useMemo, useState } from 'react'

import { useCollection } from '@/shared/firebase/hooks/useCollection.ts'
import { useEventId } from '@/shared/useEventId'
import {
    LikertFeedback,
    MultiSelectFeedback,
    OptionsSliderFeedback,
    PersonFeedbacks,
    RadioFeedback,
    TextFeedback,
    YesNoFeedback,
} from '@monorepo/functions/src/types/feedback'

import {
    isFreeText,
    isLikert,
    isMultiSelectFeedback,
    isOptionsSlider,
    isRadioFeedback,
    isYesNo,
} from '../entities/feedback/model/model.ts'
import { Filter, FilterItem } from '../features/filters/model.ts'
import { useAvailableFilters } from '../features/filters/useAvailableFilters'
import { useFilteredFeedbacks } from '../features/filters/useFilteredFeedbacks'

interface FeedbackContextType {
    textFeedbacks: TextFeedback[]
    likertFeedbacks: LikertFeedback[]
    yesNoFeedbacks: YesNoFeedback[]
    optionsSliderFeedback: OptionsSliderFeedback[]
    multiSelectFeedback: MultiSelectFeedback[]
    radioFeedback: RadioFeedback[]
    setActiveFilters: (filters: FilterItem[]) => void
    isLoading: boolean
    availableFilters: Filter[]
}

const FeedbackContext = createContext<FeedbackContextType | undefined>(undefined)

export const useFeedback = () => {
    const context = useContext(FeedbackContext)
    if (!context) {
        throw new Error('useFeedback must be used within a FeedbackProvider')
    }
    return context
}

export const FeedbackProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const eventId = useEventId()
    const [activeFilters, setActiveFilters] = useState<FilterItem[]>([])

    const { results: feedbacks, isLoading } = useCollection<PersonFeedbacks>({
        path: `events/${eventId}/feedback`,
        orderBy: ['createdAt'],
    })

    const availableFilters = useAvailableFilters(feedbacks)
    const filteredFeedbacks = useFilteredFeedbacks(feedbacks, activeFilters)

    const textFeedbacks = useMemo(() => filteredFeedbacks.filter(isFreeText), [filteredFeedbacks])
    const likertFeedbacks = useMemo(() => filteredFeedbacks.filter(isLikert), [filteredFeedbacks])
    const yesNoFeedbacks = useMemo(() => filteredFeedbacks.filter(isYesNo), [filteredFeedbacks])
    const optionsSliderFeedback = useMemo(() => filteredFeedbacks.filter(isOptionsSlider), [filteredFeedbacks])
    const multiSelectFeedback = useMemo(() => filteredFeedbacks.filter(isMultiSelectFeedback), [filteredFeedbacks])
    const radioFeedback = useMemo(() => filteredFeedbacks.filter(isRadioFeedback), [filteredFeedbacks])

    const value = {
        textFeedbacks,
        likertFeedbacks,
        yesNoFeedbacks,
        optionsSliderFeedback,
        multiSelectFeedback,
        radioFeedback,
        setActiveFilters,
        isLoading,
        availableFilters,
    }

    return <FeedbackContext.Provider value={value}>{children}</FeedbackContext.Provider>
}
