import { useEffect, useState } from 'react'

import { TrackingService } from '@/shared/lib/tracking'
import { SidebarProvider } from '@/shared/ui/sidebar'
import { useEventId } from '@/shared/useEventId.ts'
import { Category, Department, TextFeedback, WithCreatedAt } from '@monorepo/functions/src/types/feedback'

import { FilterSection } from './features/filters/ui/FilterSection'
import { SummarySection } from './features/summary/SummarySection'
import { useFeedback } from './model/FeedbackContext'
import { categories } from './model/constants.ts'
import { CommentsSidebar } from './ui/CommentsSidebar.tsx'
import { DepartmentsSection } from './ui/DepartmentsSection'
import { Header } from './ui/Header.tsx'
import { InsightsSection } from './ui/InsightsSection'
import { getCategoryColor } from './ui/colors.ts'
import { categoryIcons } from './ui/icons'

export const Dashboard = () => {
    const [selectedCategory, setSelectedCategory] = useState<Category>('Problemas')
    const [selectedDepartments, setSelectedDepartments] = useState<Department[]>([])
    const [selectedCategories, setSelectedCategories] = useState<Category[]>([])
    const eventId = useEventId()

    const { textFeedbacks, setActiveFilters, availableFilters } = useFeedback()

    useEffect(() => {
        TrackingService.trackPageView({
            eventId,
            page: 'dashboard',
        })
    }, [eventId])

    const handleCategoryClick = (category: Category) => {
        setSelectedCategory(category)
        setSelectedCategories([category])
        setSelectedDepartments([])
    }

    return (
        <SidebarProvider className='overflow-hidden bg-gradient-to-br from-gray-900 to-black text-white'>
            {/* Left Panel */}
            <div className='no-scrollbar mb-4 w-full overflow-x-auto'>
                <Header />

                <div className='p-6 pt-0'>
                    <div className='my-4'>
                        <FilterSection onFiltersChange={setActiveFilters} availableFilters={availableFilters} />
                    </div>

                    <div className='flex w-full flex-row gap-3 overflow-y-auto'>
                        {categories.map(category => (
                            <button
                                key={category}
                                className={`flex w-full flex-row items-center gap-3 rounded-lg p-3 text-left text-xl shadow-lg transition-all duration-300 ${
                                    selectedCategory === category
                                        ? `bg-gradient-to-r ${getCategoryColor(category)} text-white`
                                        : 'bg-gray-800 hover:bg-gray-700'
                                }`}
                                onClick={() => handleCategoryClick(category)}
                            >
                                <span>{categoryIcons[category]}</span>
                                {category}
                            </button>
                        ))}
                    </div>
                    <DepartmentsSection
                        selectedCategory={selectedCategory}
                        setSelectedCategories={setSelectedCategories}
                        setSelectedDepartments={setSelectedDepartments}
                        textFeedbacks={textFeedbacks}
                    />
                    <SummarySection selectedCategory={selectedCategory} />

                    <InsightsSection />
                </div>
            </div>
            <CommentsSidebar
                selectedCategory={selectedCategory}
                selectedCategories={selectedCategories}
                selectedDepartments={selectedDepartments}
                setSelectedCategories={setSelectedCategories}
                setSelectedDepartments={setSelectedDepartments}
                textFeedbacks={textFeedbacks as WithCreatedAt<TextFeedback>[]}
            />
        </SidebarProvider>
    )
}
