import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { signOut } from 'firebase/auth'
import { LogOut } from 'lucide-react'

import { getUserEvents } from '@/shared/api/clients'
import { useAuth } from '@/shared/auth'
import { auth } from '@/shared/firebase/firebase'
import { Button } from '@/shared/ui/button'
import { Event } from '@monorepo/functions/src/types/GetUserEvents'

import { EventsGrid } from './EventsGrid'

export const EventSelectorPage = () => {
    const [events, setEvents] = useState<Event[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)
    const { user } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        const fetchEvents = async () => {
            if (!user) return

            try {
                setIsLoading(true)
                setError(null)
                const result = await getUserEvents()
                setEvents(result.data.events)
            } catch (error) {
                console.error('Error fetching events:', error)
                setError('Failed to load dashboards. Please try again later.')
            } finally {
                setIsLoading(false)
            }
        }

        fetchEvents()
    }, [user])

    const handleEventSelect = (eventId: string) => {
        navigate(`/${eventId}/dashboard`)
    }

    const handleLogout = async () => {
        await signOut(auth)
        navigate('/login')
    }

    if (isLoading) {
        return (
            <div className='flex h-screen items-center justify-center bg-gradient-to-br from-gray-900 to-black'>
                <div className='text-xl text-white'>Cargando...</div>
            </div>
        )
    }

    return (
        <div className='min-h-screen bg-gradient-to-br from-gray-900 to-black p-8'>
            <div className='mx-auto max-w-7xl'>
                <div className='mb-8 flex items-center justify-between'>
                    <div>
                        <img className='w-80' src='/boothbits-logo.svg' alt='BoothBits logo' />
                        <h1 className='mt-4 bg-gradient-to-r from-blue-400 to-cyan-600 bg-clip-text text-3xl font-bold text-transparent'>
                            Seleccionar Evento
                        </h1>
                    </div>
                    <Button
                        variant='ghost'
                        onClick={handleLogout}
                        className='flex items-center gap-2 text-white hover:bg-gray-800'
                    >
                        <LogOut className='h-4 w-4' />
                        Cerrar Sesión
                    </Button>
                </div>

                {error && (
                    <div className='mb-8 rounded-lg border border-red-500 bg-red-500/10 p-4 text-red-500'>
                        <p>{error}</p>
                    </div>
                )}

                <EventsGrid events={events} onEventSelect={handleEventSelect} />
            </div>
        </div>
    )
}
