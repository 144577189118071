import {
    LikertFeedback,
    MultiSelectFeedback,
    OptionsSliderFeedback,
    PersonFeedback,
    RadioFeedback,
    TextFeedback,
    YesNoFeedback,
} from '@monorepo/functions/src/types/feedback'

export const isFreeText = (personFeedback: PersonFeedback): personFeedback is TextFeedback =>
    personFeedback.question.type === 'FREE_TEXT'

export const isLikert = (personFeedback: PersonFeedback): personFeedback is LikertFeedback =>
    personFeedback.question.type === 'SLIDER'

export const isYesNo = (personFeedback: PersonFeedback): personFeedback is YesNoFeedback =>
    personFeedback.question.type === 'YES_NO'

export const isOptionsSlider = (personFeedback: PersonFeedback): personFeedback is OptionsSliderFeedback =>
    personFeedback.question.type === 'OPTIONS_SLIDER'

export const isMultiSelectFeedback = (personFeedback: PersonFeedback): personFeedback is MultiSelectFeedback =>
    personFeedback.question.type === 'MULTI_SELECT'

export const isRadioFeedback = (personFeedback: PersonFeedback): personFeedback is RadioFeedback =>
    personFeedback.question.type === 'RADIO'
