import { Search } from 'lucide-react'

import { Filter } from '../model'

interface FilterSearchProps {
    searchTerm: string
    onSearchChange: (term: string) => void
    filteredAvailableFilters: Filter[]
    onFilterSelect: (filter: Filter) => void
}

export const FilterSearch = ({
    searchTerm,
    onSearchChange,
    filteredAvailableFilters,
    onFilterSelect,
}: FilterSearchProps) => {
    return (
        <div className='absolute left-0 top-full z-[60] mt-1 min-w-[300px] overflow-hidden rounded-md border border-gray-700 bg-gray-900 shadow-lg'>
            <div className='border-b border-gray-700 p-2'>
                <div className='flex items-center gap-2 rounded-sm bg-gray-800 px-2 py-1'>
                    <Search className='h-4 w-4 text-gray-400' />
                    <input
                        type='text'
                        className='w-full bg-transparent text-sm text-white placeholder-gray-400 focus:outline-none'
                        placeholder='Buscar filtro...'
                        value={searchTerm}
                        onChange={e => onSearchChange(e.target.value)}
                    />
                </div>
            </div>
            <div className='max-h-60 overflow-auto py-1'>
                {filteredAvailableFilters.map(filter => (
                    <button
                        key={filter.title}
                        className='flex w-full items-start px-3 py-1.5 text-left text-sm text-gray-300 hover:bg-gray-800'
                        onClick={() => onFilterSelect(filter)}
                    >
                        {filter.title}
                    </button>
                ))}
            </div>
        </div>
    )
}
