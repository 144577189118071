import { X } from 'lucide-react'

import { Button } from '@/shared/ui/button'

import { Filter, FilterItem } from '../model'
import { MultiSelectDropdown } from './MultiSelectDropdown'

interface FilterBadgeProps {
    filter: FilterItem
    question: Filter
    onRemove: (id: string) => void
    onUpdate: (id: string, values: string[]) => void
    onMouseEnter: (e: React.MouseEvent, title: string) => void
    onMouseLeave: () => void
}

const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text
    return text.slice(0, maxLength) + '...'
}

export const FilterBadge = ({ filter, question, onRemove, onUpdate, onMouseEnter, onMouseLeave }: FilterBadgeProps) => {
    const selectedOptions = question.options.filter(opt => filter.selectedValues.includes(opt.value))
    const displayText = selectedOptions.map(opt => opt.label).join(', ')

    return (
        <div
            className='relative flex h-8 shrink-0 items-center gap-1 rounded-md border border-gray-700 bg-gray-900 px-2'
            onMouseEnter={e => onMouseEnter(e, filter.title)}
            onMouseLeave={onMouseLeave}
        >
            <div className='flex items-center gap-1'>
                <MultiSelectDropdown
                    trigger={
                        <button className='text-sm text-gray-200 hover:text-white' title={filter.title}>
                            {truncateText(filter.title, 25)}
                        </button>
                    }
                    options={question.options}
                    selectedValues={filter.selectedValues}
                    onChange={values => onUpdate(filter.id, values)}
                    placeholder='Select options...'
                />
                {filter.selectedValues.length > 0 && (
                    <>
                        <span className='text-gray-500'>:</span>
                        <span className='text-sm text-gray-400' title={displayText}>
                            {truncateText(displayText, 30)}
                        </span>
                    </>
                )}
            </div>

            <Button
                variant='ghost'
                size='sm'
                className='ml-2 h-6 w-6 shrink-0 p-0 text-gray-500 hover:bg-gray-800 hover:text-gray-300'
                onClick={() => onRemove(filter.id)}
            >
                <X className='h-3 w-3' />
            </Button>
        </div>
    )
}
