import { Info } from 'lucide-react'

import { getColorForValue, getShortDescription } from '@/pages/form/components/LikertInfoModal'
import { Button } from '@/shared/ui/button'
import { LikertFeedback } from '@monorepo/functions/src/types/feedback'

import { InsightsCard } from './InsightsCard'

interface Props {
    feedbacks: LikertFeedback[]
    onLikertModalOpen: () => void
}

const getAverageScore = (feedbacks: LikertFeedback[], question: string) => {
    const filteredFeedbacks = feedbacks.filter(f => f.question.title === question)
    const sum = filteredFeedbacks.reduce((a, b) => a + b.answer.value, 0)
    const avg = sum / filteredFeedbacks.length

    return Math.round(avg * 100) / 100
}

export const LikertInsights = ({ feedbacks, onLikertModalOpen }: Props) => {
    const feedbackByTitle = feedbacks.reduce(
        (acc, feedback) => {
            const title = feedback.question.title

            if (!acc[title]) acc[title] = []
            acc[title].push(feedback)
            return acc
        },
        {} as Record<string, LikertFeedback[]>,
    )

    return Object.entries(feedbackByTitle).map(([title, filteredFeedbacks]) => {
        const avg = getAverageScore(filteredFeedbacks, title)

        return (
            <InsightsCard key={title} numberOfFeedbacks={filteredFeedbacks.length} questionTitle={title}>
                <div className='flex h-auto flex-col items-center justify-center py-4'>
                    <span className={`text-6xl font-bold text-${getColorForValue(avg)} tooltip`}>
                        {avg.toFixed(1)}
                        <Button
                            onClick={onLikertModalOpen}
                            className='absolute right-[-30px] top-[-15px] ml-2 flex h-8 w-8 items-center justify-center rounded-full bg-transparent p-2 hover:bg-gray-700'
                            aria-label='Abrir explicación de la Escala Likert'
                        >
                            <Info className='h-5 w-5 text-gray-400 hover:text-white' />
                        </Button>
                    </span>
                    <div className='mt-2 text-center'>
                        <span className={`text-sm text-${getColorForValue(avg)}`}>{getShortDescription(avg)}</span>
                    </div>
                </div>
            </InsightsCard>
        )
    })
}
