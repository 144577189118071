import { PersonFeedbacks } from '@monorepo/functions/src/types/feedback'

import { FlatFeedback } from './model'

export const adaptPersonFeedbacksToFlatFeedbacks = (feedbacks: PersonFeedbacks[]): FlatFeedback[] =>
    feedbacks.flatMap(f =>
        f.feedbacks.map(personFeedbacks => ({
            ...personFeedbacks,
            createdAt: f.createdAt,
            location: f.location,
        })),
    )
