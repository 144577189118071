import { MultiSelectFeedback } from '@monorepo/functions/src/types/feedback'

import { InsightsCard } from './InsightsCard'
import { InsightsPieChart } from './InsightsPieChart'

interface Props {
    feedbacks: MultiSelectFeedback[]
}

const getMultiSelectChartData = (
    question: string,
    feedbacks: MultiSelectFeedback[],
): { label: string; value: number; fill: string }[] => {
    const answers = feedbacks.filter(q => q.question.title === question).map(r => r.answer)
    const possibleAnswers = feedbacks[0].question.options

    return possibleAnswers.map((answer, index) => ({
        value: answers.filter(a => a.value.includes(answer.value)).length,
        label: answer.label,
        fill: `hsl(var(--chart-${index + 1}))`,
    }))
}

export const MultiSelectInsights = ({ feedbacks }: Props) => {
    const feedbackByTitle = feedbacks.reduce(
        (acc, feedback) => {
            const title = feedback.question.title

            if (!acc[title]) acc[title] = []
            acc[title].push(feedback)
            return acc
        },
        {} as Record<string, MultiSelectFeedback[]>,
    )

    return Object.entries(feedbackByTitle).map(([title, filteredFeedbacks]) => {
        const radioChartData = getMultiSelectChartData(title, filteredFeedbacks)

        return (
            <InsightsCard key={title} questionTitle={title}>
                <InsightsPieChart data={radioChartData} />
            </InsightsCard>
        )
    })
}
