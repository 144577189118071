import { YesNoFeedback } from '@monorepo/functions/src/types/feedback'

import { InsightsCard } from './InsightsCard'
import { InsightsPieChart } from './InsightsPieChart'

interface Props {
    feedbacks: YesNoFeedback[]
}

const getYesNoChartData = (
    question: string,
    feedbacks: YesNoFeedback[],
): { label: string; value: number; fill: string }[] => {
    const answers = feedbacks.filter(q => q.question.title === question).map(r => r.answer)

    return [
        {
            value: answers.filter(a => a.value).length,
            label: 'Sí',
            fill: `hsl(var(--chart-1))`,
        },
        {
            value: answers.filter(a => !a.value).length,
            label: 'No',
            fill: `hsl(var(--chart-2))`,
        },
    ]
}

export const YesNoInsights = ({ feedbacks }: Props) => {
    const feedbackByTitle = feedbacks.reduce(
        (acc, feedback) => {
            const title = feedback.question.title

            if (!acc[title]) acc[title] = []
            acc[title].push(feedback)
            return acc
        },
        {} as Record<string, YesNoFeedback[]>,
    )

    return Object.entries(feedbackByTitle).map(([title, filteredFeedbacks]) => {
        const chartData = getYesNoChartData(title, filteredFeedbacks)

        return (
            <InsightsCard key={title} questionTitle={title}>
                <InsightsPieChart data={chartData} />
            </InsightsCard>
        )
    })
}
