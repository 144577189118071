import { DocumentsProvider } from '@/shared/firebase/hooks/useDocument'

import { Dashboard as InnerDashboard } from './Dashboard'
import { FeedbackProvider } from './model/FeedbackContext'

export const Dashboard = () => (
    <FeedbackProvider>
        <DocumentsProvider>
            <InnerDashboard />
        </DocumentsProvider>
    </FeedbackProvider>
)
