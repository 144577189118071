import { useRef, useState } from 'react'

import { Filter } from 'lucide-react'

import { FilterItem } from '@/pages/dashboard/model/FeedbackContext'
import { useClickOutside } from '@/shared/hooks/useClickOutside'
import { Button } from '@/shared/ui/button'

import { useFilters } from '../useFilters'
import { FilterBadge } from './FilterBadge'
import { FilterSearch } from './FilterSearch'

interface FilterSectionProps {
    onFiltersChange: (filters: FilterItem[]) => void
    availableFilters: Filter[]
}

export const FilterSection = ({ onFiltersChange, availableFilters }: FilterSectionProps) => {
    const [isAddingFilter, setIsAddingFilter] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [hoveredFilter, setHoveredFilter] = useState<string | null>(null)
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 })

    const addFilterRef = useRef<HTMLDivElement>(null)
    const { filters, addFilter, removeFilter, updateFilter } = useFilters(onFiltersChange)

    useClickOutside(addFilterRef, () => setIsAddingFilter(false))

    const filteredAvailableFilters = availableFilters.filter(
        q => q.title.toLowerCase().includes(searchTerm.toLowerCase()) && !filters.some(f => f.title === q.title),
    )

    const handleFilterMouseEnter = (event: React.MouseEvent, questionTitle: string) => {
        const rect = event.currentTarget.getBoundingClientRect()
        setTooltipPosition({
            top: rect.top - 8,
            left: rect.left + rect.width / 2,
        })
        setHoveredFilter(questionTitle)
    }

    return (
        <div className='flex h-full flex-col'>
            <div className='flex min-h-[40px] items-center gap-2 py-2'>
                <div className='relative' ref={addFilterRef}>
                    <Button
                        variant='outline'
                        className='flex h-8 shrink-0 items-center gap-2 rounded-md border border-gray-700 bg-gray-900 px-3 text-sm text-gray-400 hover:bg-gray-800'
                        onClick={() => setIsAddingFilter(!isAddingFilter)}
                    >
                        <Filter className='h-4 w-4' />
                        Filtrar
                    </Button>

                    {isAddingFilter && (
                        <FilterSearch
                            searchTerm={searchTerm}
                            onSearchChange={setSearchTerm}
                            filteredAvailableFilters={filteredAvailableFilters}
                            onFilterSelect={addFilter}
                        />
                    )}
                </div>

                <div className='flex-1 overflow-x-auto'>
                    <div className='flex items-center gap-2 pr-4'>
                        {filters.map(filter => {
                            const question = availableFilters.find(q => q.title === filter.title)
                            if (!question?.options) return null

                            return (
                                <FilterBadge
                                    key={filter.id}
                                    filter={filter}
                                    question={question}
                                    onRemove={id => {
                                        setHoveredFilter(null)
                                        removeFilter(id)
                                    }}
                                    onUpdate={updateFilter}
                                    onMouseEnter={handleFilterMouseEnter}
                                    onMouseLeave={() => setHoveredFilter(null)}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>

            {hoveredFilter && (
                <div
                    className='pointer-events-none fixed z-[80] -translate-x-1/2 -translate-y-full'
                    style={{
                        top: `${tooltipPosition.top}px`,
                        left: `${tooltipPosition.left}px`,
                    }}
                >
                    <div className='relative'>
                        <div className='max-w-md rounded bg-gray-800 px-3 py-2 text-sm text-white shadow-xl ring-1 ring-gray-700/50'>
                            {hoveredFilter}
                        </div>
                        <div className='absolute left-1/2 top-full -translate-x-1/2 border-4 border-transparent border-t-gray-800' />
                    </div>
                </div>
            )}
        </div>
    )
}
