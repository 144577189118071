import { ReactNode } from 'react'

import {
    AlertTriangle,
    Coffee,
    DollarSign,
    HelpCircle,
    MessageSquare,
    Music,
    Shield,
    Thermometer,
    ThumbsUp,
    Users,
} from 'lucide-react'

import { Category } from '@monorepo/functions/src/types/feedback'

export const categoryIcons: { [key in Category]: ReactNode } = {
    Problemas: <AlertTriangle className='size-5' />,
    Peticiones: <MessageSquare className='size-5' />,
    Fortalezas: <ThumbsUp className='size-5' />,
    Otros: <HelpCircle className='size-5' />,
}

export const departmentIcons = {
    Música: <Music className='h-4 w-4' />,
    Ambiente: <Thermometer className='h-4 w-4' />,
    Personal: <Users className='h-4 w-4' />,
    Seguridad: <Shield className='h-4 w-4' />,
    Servicios: <Coffee className='h-4 w-4' />,
    Pricing: <DollarSign className='h-4 w-4' />,
    Otros: <HelpCircle className='h-4 w-4' />,
}
