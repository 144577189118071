import { RadioFeedback } from '@monorepo/functions/src/types/feedback'

import { InsightsCard } from './InsightsCard'
import { InsightsPieChart } from './InsightsPieChart'
import { getSingleSelectChartData } from './getSingleSelectChartData'

interface Props {
    feedbacks: RadioFeedback[]
}

export const RadioInsights = ({ feedbacks }: Props) => {
    const feedbackByTitle = feedbacks.reduce(
        (acc, feedback) => {
            const title = feedback.question.title

            if (!acc[title]) acc[title] = []
            acc[title].push(feedback)
            return acc
        },
        {} as Record<string, RadioFeedback[]>,
    )

    return Object.entries(feedbackByTitle).map(([title, filteredFeedbacks]) => {
        const radioChartData = getSingleSelectChartData(title, filteredFeedbacks)

        return (
            <InsightsCard key={title} questionTitle={title}>
                <InsightsPieChart data={radioChartData} />
            </InsightsCard>
        )
    })
}
