import { Category } from '@monorepo/functions/src/types/feedback'

export const getCategoryColor = (category: Category) => {
    const colors = {
        Problemas: 'from-red-500 to-orange-500',
        Peticiones: 'from-blue-500 to-cyan-500',
        Fortalezas: 'from-green-500 to-emerald-500',
        Otros: 'from-yellow-500 to-amber-500',
    }
    return colors[category] || 'from-gray-500 to-gray-700'
}
