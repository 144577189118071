import { FC, useEffect } from 'react'
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'

import { WithAnonymousUser } from '@/app/navigation/WithAnonymousUser.tsx'
import { getUserLocation } from '@/app/navigation/location.ts'
import { Dashboard } from '@/pages/dashboard'
import { EventSelectorPage } from '@/pages/events-selector'
import DefaultFormOnboarding from '@/pages/form/FormOnboarding.tsx'
import DefaultFormSummary from '@/pages/form/FormSummary.tsx'
import { Layout as BaseLayout } from '@/pages/form/Layout.tsx'
import { UserForm as BaseUserForm } from '@/pages/form/UserForm.tsx'
import BimeLogo from '@/pages/form/assets/img/bime-logo-black.png'
import ChockLogoBlack from '@/pages/form/assets/img/chok-logo-black.svg'
import { FormOnboarding as BimeFormOnboarding, FormSummary as BimeFormSummary } from '@/pages/form/bime'
import { FormOnboarding as ChokFormOnboarding, FormSummary as ChokFormSummary } from '@/pages/form/chok'
import { FillWaterAnimationProvider } from '@/pages/form/components/FillWaterAnimationContext.tsx'
import { EventFormOnboardingProps } from '@/pages/form/types.ts'
import { useEventFeedback } from '@/pages/form/useEventFeedback.ts'
import { LoginPage } from '@/pages/login/LoginPage.tsx'
import { DocumentsProvider, useDocument } from '@/shared/firebase/hooks/useDocument.tsx'
import { TrackingService } from '@/shared/lib/tracking/index.ts'
import { useEventId } from '@/shared/useEventId.ts'
import { Questions } from '@monorepo/functions/src/types/event'

import { ProtectedPage } from './ProtectedPage.tsx'
import { useEventIdStart } from './useEventIdStart.ts'
import { useQuery } from './useQuery.ts'

const LANDING_URL = 'https://boothbits.com'

const ParamChecker = ({ mandatoryParams }: { mandatoryParams: string[] }) => {
    const params = useParams()

    const areMandatoryParamsPresent = mandatoryParams.every(param => params[param])

    return areMandatoryParamsPresent ? <Outlet /> : <Navigate to='/error' replace />
}

const EventToLayoutClassMap: { [key in string]: string } = {
    chok: 'chok font-chok-courier',
    bime: 'bime font-bime-forma',
}

const Layout = () => {
    const eventIdStart = useEventIdStart()
    const eventId = useEventId()
    const source = useQuery('source')
    const location = useLocation()

    useEffect(() => {
        TrackingService.trackPageView({
            eventId,
            page: location.pathname.split('/')[2],
            source,
        })
    }, [location, eventId, source])

    const eventClassNames = EventToLayoutClassMap[eventIdStart] ?? ''

    return (
        <DocumentsProvider>
            <BaseLayout className={eventClassNames} />
        </DocumentsProvider>
    )
}

const EventToOnboardingMap: { [key in string]: FC<EventFormOnboardingProps> } = {
    bime: BimeFormOnboarding,
    chok: ChokFormOnboarding,
}

const FormOnboarding = () => {
    const eventIdStart = useEventIdStart()
    const EventFormOnboarding = EventToOnboardingMap[eventIdStart] ?? DefaultFormOnboarding
    const navigate = useNavigate()
    const eventId = useEventId()
    const { setEventFeedback, eventFeedback } = useEventFeedback()

    const { document: questions, isLoading: areQuestionsLoading } = useDocument<Questions>({
        collectionName: `/events/${eventId}/questions`,
        id: 'default',
    })

    const startForm = () => {
        if (!eventFeedback.location?.country) {
            getUserLocation().then(location => {
                setEventFeedback({
                    location,
                })
            })
        }
        TrackingService.trackEvent('Start feedback form', { page: 'onboarding' }, { send_immediately: true })
        navigate('./form')
    }

    if (!areQuestionsLoading && !questions) window.location.href = LANDING_URL

    return <EventFormOnboarding onStartForm={startForm} />
}

const EventToSummaryMap: { [key in string]: FC } = {
    chok: ChokFormSummary,
    bime: BimeFormSummary,
}

const FormSummary = () => {
    const eventIdStart = useEventIdStart()
    const EventFormSummary = EventToSummaryMap[eventIdStart] ?? DefaultFormSummary

    return <EventFormSummary />
}

const EventToLogoComponentMap: { [key in string]: JSX.Element } = {
    chok: <img className='ml-auto mr-auto h-12' src={ChockLogoBlack} alt='Logo chök' />,
    bime: <img className='ml-auto mr-auto mt-2 h-12' src={BimeLogo} alt='Logo BIME' />,
}

const UserForm = () => {
    const eventIdStart = useEventIdStart()

    const LogoComponent: JSX.Element = EventToLogoComponentMap[eventIdStart]

    return <BaseUserForm Logo={LogoComponent} />
}

export const RoutingComponent = () => (
    <Routes>
        <Route path='/login' element={<LoginPage />} />
        <Route
            path='/'
            element={
                <ProtectedPage>
                    <EventSelectorPage />
                </ProtectedPage>
            }
        />
        <Route
            path='/demo/dashboard'
            element={
                <ProtectedPage>
                    <Dashboard />
                </ProtectedPage>
            }
        />
        <Route element={<ParamChecker mandatoryParams={['eventId']} />}>
            <Route
                path='/:eventId/dashboard'
                element={
                    <ProtectedPage>
                        <Dashboard />
                    </ProtectedPage>
                }
            />
        </Route>
        <Route path='/:eventId' element={<Layout />}>
            <Route
                path='/:eventId'
                element={
                    <WithAnonymousUser userType='End User'>
                        <FormOnboarding />
                    </WithAnonymousUser>
                }
            />
            <Route
                path='/:eventId/form'
                element={
                    <WithAnonymousUser userType='End User'>
                        <FillWaterAnimationProvider>
                            <UserForm />
                        </FillWaterAnimationProvider>
                    </WithAnonymousUser>
                }
            />
            <Route
                path='/:eventId/form-summary'
                element={
                    <WithAnonymousUser userType='End User'>
                        <FormSummary />
                    </WithAnonymousUser>
                }
            />
        </Route>
        <Route path='*' element={<Navigate to='/' replace />} />
    </Routes>
)
