import { useMemo } from 'react'

import { Label, Pie, PieChart } from 'recharts'

import { ChartContainer, ChartLegend, ChartLegendContent, ChartTooltip, ChartTooltipContent } from '@/shared/ui/chart'

interface Props {
    data: { label: string; value: number; fill: string }[]
}

const getDefaultChartConfig = (chartData: Props['data']) =>
    chartData.reduce(
        (accum, curr) => {
            if (!accum[curr.label]) accum[curr.label] = { label: curr.label }
            return accum
        },
        {} as { [key in string]: { label: string } },
    )

const LEGEND_PADDING_TOP = 20
const LEGEND_ITEM_HEIGHT = 16
const LEGEND_GAP = 8
const PIE_HEIGHT = 250

export const InsightsPieChart = ({ data }: Props) => {
    const [totalResponses, filteredData] = useMemo(
        () => [data.reduce((acc, curr) => acc + curr.value, 0), data.filter(({ value }) => value > 0)],
        [data],
    )

    const chartConfig = getDefaultChartConfig(filteredData)
    const numberOfItems = Object.keys(chartConfig).length
    const legendHeight = LEGEND_PADDING_TOP + numberOfItems * LEGEND_ITEM_HEIGHT + (LEGEND_GAP * numberOfItems - 1)

    return (
        <ChartContainer
            config={chartConfig}
            className={`[&_.recharts-pie-label-text]:fill-foreground`}
            responsiveContainerProps={{ height: PIE_HEIGHT + legendHeight, width: '100%' }}
        >
            <PieChart>
                <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
                <Pie data={filteredData} dataKey='value' nameKey='label' innerRadius='65%' outerRadius='80%' label>
                    <Label
                        content={({ viewBox }) => {
                            if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                                return (
                                    <text x={viewBox.cx} y={viewBox.cy} textAnchor='middle' dominantBaseline='middle'>
                                        <tspan
                                            x={viewBox.cx}
                                            y={viewBox.cy}
                                            className='fill-foreground text-3xl font-bold'
                                        >
                                            {totalResponses.toLocaleString()}
                                        </tspan>
                                        <tspan
                                            x={viewBox.cx}
                                            y={(viewBox.cy || 0) + 24}
                                            className='fill-muted-foreground'
                                        >
                                            Respuesta{totalResponses === 1 ? '' : 's'}
                                        </tspan>
                                    </text>
                                )
                            }
                        }}
                    />
                </Pie>
                <ChartLegend height={legendHeight} layout='vertical' content={<ChartLegendContent nameKey='label' />} />
            </PieChart>
        </ChartContainer>
    )
}
