import { Timestamp } from 'firebase/firestore'

import { PersonFeedbacks, TextFeedback } from '@monorepo/functions/src/types/feedback'

type TextFeedbackWithCreatedAt = TextFeedback & { createdAt: PersonFeedbacks['createdAt'] }

type CommentCardProps = {
    comment: TextFeedbackWithCreatedAt
}

// TODO converting the DB format to the UI should be done in a mapper, but we don't have any for now
const formatTimestampToddMMyyyy = (timestamp: FirebaseFirestore.Timestamp) => {
    if (!(timestamp instanceof Timestamp)) return ''

    return timestamp.toDate().toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    })
}

export const CommentCard = ({ comment }: CommentCardProps) => {
    const createdAt = formatTimestampToddMMyyyy(comment.createdAt)

    return (
        <div className='flex w-full flex-col gap-2 rounded-lg bg-gray-800 bg-opacity-50 p-4 shadow-lg backdrop-blur-sm transition-all duration-300 hover:scale-105'>
            <h4 className='bg-clip-text font-semibold text-pink-500'>{comment.question.title}</h4>
            {/* categories and departments as daisyui badge */}
            <div className='flex flex-wrap gap-2'>
                {comment.answer.categories.map(cat => (
                    <span key={cat} className={`badge badge-primary badge-outline rounded-full`}>
                        {cat}
                    </span>
                ))}
                {comment.answer.departments.map(dept => (
                    <span key={dept} className='badge badge-primary badge-outline rounded-full'>
                        {dept}
                    </span>
                ))}
            </div>
            <p>{comment.answer.value}</p>
            {createdAt && <p className='text-right'>{createdAt}</p>}
        </div>
    )
}
