import { ReactNode } from 'react'

import { Users } from 'lucide-react'

import { cn } from '@/shared/lib/styles/class-merge'

interface Props {
    questionTitle: string
    numberOfFeedbacks?: number
    children: ReactNode
}

export const InsightsCard = ({ questionTitle, numberOfFeedbacks, children }: Props) => (
    <div className='grid h-fit break-inside-avoid gap-3 rounded-lg bg-gray-800 bg-opacity-50 p-4 shadow-xl backdrop-blur-sm'>
        <div className='flex items-start justify-between gap-4'>
            <h4 className={cn('w-full font-semibold', !numberOfFeedbacks && 'text-center')}>{questionTitle}</h4>
            {numberOfFeedbacks && (
                <div className='flex items-center gap-1 pt-0.5'>
                    <Users className='mr-1 h-4 w-4' />
                    <span className='text-sm'>{numberOfFeedbacks}</span>
                </div>
            )}
        </div>
        <div className='flex w-full items-center justify-center'>{children}</div>
    </div>
)
