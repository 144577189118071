import {
    Calendar,
    ChevronRight,
    Clock,
    MessageCircle,
    MessageSquare,
    SlidersHorizontal,
    Star,
    ToggleLeft,
    Users,
} from 'lucide-react'

import { Event } from '@monorepo/functions/src/types/GetUserEvents'

interface EventCardProps {
    event: Event
    onSelect: (eventId: string) => void
}

const ICON_COLORS = {
    totalFeedbacks: 'text-blue-400',
    totalPeople: 'text-indigo-400',
    rating: 'text-yellow-400',
    slider: 'text-green-400',
    text: 'text-purple-400',
    yesNo: 'text-pink-400',
    timestamp: 'text-cyan-400',
} as const

const StatItem = ({
    icon: Icon,
    value,
    label,
    color,
}: {
    icon: any
    value: number | string
    label?: string
    color: string
}) => (
    <div className='flex items-center gap-2 text-sm'>
        <Icon className={`h-4 w-4 ${color}`} />
        <span className='text-white'>
            {value} {label}
        </span>
    </div>
)

const TimeStamp = ({ icon: Icon, label, date }: { icon: any; label: string; date: string }) => (
    <div className='flex items-center gap-2 text-sm'>
        <Icon className={`h-4 w-4 ${ICON_COLORS.timestamp}`} />
        <span className='text-gray-400'>{label}</span>
        <span className='text-white'>{new Date(date).toLocaleDateString()}</span>
    </div>
)

export const EventCard = ({ event, onSelect }: EventCardProps) => {
    const { id, name, description, summary, createdAt } = event

    return (
        <button
            onClick={() => onSelect(id)}
            className='group flex flex-col rounded-lg border border-gray-700 bg-gray-800 p-6 text-left transition-all duration-200 hover:border-blue-500 hover:bg-gray-700'
        >
            <div className='flex items-center justify-between'>
                <h2 className='text-2xl font-bold text-white'>{name}</h2>
                <ChevronRight className='h-5 w-5 transform text-gray-400 transition-transform group-hover:translate-x-1 group-hover:text-blue-400' />
            </div>

            {description && <p className='mt-3 text-sm text-gray-400'>{description}</p>}

            {summary && (
                <div className='mt-4 space-y-3 border-t border-gray-700 pt-4'>
                    <div className='grid grid-cols-2 gap-3 sm:grid-cols-3'>
                        <StatItem icon={Users} value={summary.totalPeople} color={ICON_COLORS.totalPeople} />
                        <StatItem
                            icon={MessageSquare}
                            value={summary.totalFeedbacks}
                            color={ICON_COLORS.totalFeedbacks}
                        />
                        {summary.averageRating != null && (
                            <StatItem icon={Star} value={summary.averageRating.toFixed(1)} color={ICON_COLORS.rating} />
                        )}
                    </div>

                    <div className='grid grid-cols-2 gap-3 sm:grid-cols-2'>
                        <StatItem
                            icon={SlidersHorizontal}
                            value={summary.totalSliderResponses}
                            label='valoraciones'
                            color={ICON_COLORS.slider}
                        />
                        <StatItem
                            icon={MessageCircle}
                            value={summary.totalTextResponses}
                            label='textos'
                            color={ICON_COLORS.text}
                        />
                        <StatItem
                            icon={ToggleLeft}
                            value={summary.totalYesNoResponses}
                            label='sí/no'
                            color={ICON_COLORS.yesNo}
                        />
                    </div>

                    <div className='flex flex-col gap-2'>
                        {summary.lastFeedbackAt && (
                            <TimeStamp icon={Clock} label='Último feedback:' date={summary.lastFeedbackAt} />
                        )}
                        {createdAt && <TimeStamp icon={Calendar} label='Creado:' date={createdAt} />}
                    </div>
                </div>
            )}
        </button>
    )
}
