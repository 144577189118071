import { useState } from 'react'

import { Filter, FilterItem } from './model'

export const useFilters = (onFiltersChange: (filters: FilterItem[]) => void) => {
    const [filters, setFilters] = useState<FilterItem[]>([])

    const addFilter = (filter: Filter) => {
        const newFilter: FilterItem = {
            id: crypto.randomUUID(),
            title: filter.title,
            type: filter.type,
            selectedValues: [],
        }
        const updatedFilters = [...filters, newFilter]
        setFilters(updatedFilters)
        onFiltersChange(updatedFilters)
    }

    const removeFilter = (id: string) => {
        const updatedFilters = filters.filter(f => f.id !== id)
        setFilters(updatedFilters)
        onFiltersChange(updatedFilters)
    }

    const updateFilter = (id: string, selectedValues: string[]) => {
        const updatedFilters = filters.map(f => (f.id === id ? { ...f, selectedValues } : f))
        setFilters(updatedFilters)
        onFiltersChange(updatedFilters)
    }

    return {
        filters,
        addFilter,
        removeFilter,
        updateFilter,
    }
}
