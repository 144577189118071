import { motion } from 'framer-motion'

import ChockSymbolBlack from '@/pages/form/assets/img/chok-symbol-black.svg'
import { PoweredBy } from '@/pages/form/components/PoweredBy.tsx'

import { EventFormOnboardingProps } from '../types'

export const FormOnboarding: React.FC<EventFormOnboardingProps> = ({ onStartForm }) => (
    <>
        <div className='z-10 flex flex-col items-center justify-center'>
            <motion.h1
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className='mb-8 text-center text-3xl font-extrabold tracking-wide'
            >
                Cuéntanos tu experiencia
            </motion.h1>
            {/* Animated Icon */}
            <motion.div
                animate={{
                    scale: [0.5, 1, 1.2],
                }}
                transition={{ duration: 1 }}
                className='mb-8'
            >
                <img className='h-10' src={ChockSymbolBlack} alt='Logo chök' />
            </motion.div>

            <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.6, duration: 0.5 }}
                className='mx-4 mb-8 w-full max-w-sm text-center'
            >
                <h2 className='mb-3 text-xl'>Haz que la próxima visita sea aún más irresistible</h2>
            </motion.div>

            <motion.button
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.9, duration: 0.5 }}
                onClick={onStartForm}
                className='mb-8 transform rounded-lg bg-black px-8 py-3 font-bold text-white transition duration-300 ease-in-out hover:scale-105 hover:bg-black/80'
            >
                Comenzar
            </motion.button>
            <PoweredBy />
        </div>
    </>
)
