import { useState } from 'react'

import { Info } from 'lucide-react'

import { LikertInfoModal } from '@/pages/form/components/LikertInfoModal'
import { cn } from '@/shared/lib/styles'
import { Button } from '@/shared/ui/button'
import { useSidebar } from '@/shared/ui/sidebar'

import { useFeedback } from '../model/FeedbackContext'
import { LikertInsights } from './charts/LikertInsights'
import { MultiSelectInsights } from './charts/MultiSelectInsights'
import { OptionsSliderInsights } from './charts/OptionsSliderInsights'
import { RadioInsights } from './charts/RadioInsights'
import { YesNoInsights } from './charts/YesNoInsights'

export const InsightsSection = () => {
    const [isLikertModalOpen, setIsLikertModalOpen] = useState(false)
    const { open: isSidebarOpen } = useSidebar()

    const { likertFeedbacks, yesNoFeedbacks, optionsSliderFeedback, multiSelectFeedback, radioFeedback } = useFeedback()

    const toggleLikertModal = () => {
        setIsLikertModalOpen(prev => !prev)
    }

    return (
        <div className='mt-8'>
            <div className='flex items-center justify-between'>
                <h3 className='mb-4 bg-gradient-to-r from-blue-400 to-cyan-600 bg-clip-text text-xl font-semibold text-transparent'>
                    Insights
                </h3>
                <Button
                    onClick={toggleLikertModal}
                    className='mb-4 bg-gray-700 text-white hover:bg-gray-600'
                    aria-label='Información sobre la Escala Likert'
                >
                    <Info className='mr-2 h-4 w-4' />
                    Escala Likert
                </Button>
            </div>
            <div className={cn('columns-1 gap-3 space-y-4 md:columns-2', !isSidebarOpen && 'xl:columns-3')}>
                <LikertInsights feedbacks={likertFeedbacks} onLikertModalOpen={toggleLikertModal} />
                <RadioInsights feedbacks={radioFeedback} />
                <MultiSelectInsights feedbacks={multiSelectFeedback} />
                <YesNoInsights feedbacks={yesNoFeedbacks} />
                <OptionsSliderInsights feedbacks={optionsSliderFeedback} />
            </div>
            <LikertInfoModal isOpen={isLikertModalOpen} onClose={toggleLikertModal} />
        </div>
    )
}
