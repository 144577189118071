import { useMemo } from 'react'

import { PersonFeedbacks } from '@monorepo/functions/src/types/feedback'

import { adaptPersonFeedbacksToFlatFeedbacks } from '../../entities/feedback/model/flatFeedback/adaptFromPersonFeedbacks'
import {
    isLikert,
    isMultiSelectFeedback,
    isOptionsSlider,
    isRadioFeedback,
    isYesNo,
} from '../../entities/feedback/model/model'
import { FilterItem } from './model'

export const useFilteredFeedbacks = (feedbacks: PersonFeedbacks[], activeFilters: FilterItem[]) => {
    const flatFeedbacks = useMemo(() => adaptPersonFeedbacksToFlatFeedbacks(feedbacks), [feedbacks])

    return useMemo(() => {
        const activeFiltersWithValues = activeFilters.filter(filter => filter.selectedValues.length > 0)

        // If no filters have selected values, return all feedbacks
        if (activeFiltersWithValues.length === 0) return flatFeedbacks

        const filteredFeedbacks = feedbacks.filter(userFeedback => {
            return activeFiltersWithValues.every(filter => {
                if (filter.type === 'COUNTRY') {
                    // Find the specific feedback for this filter's question
                    return userFeedback.location && filter.selectedValues.includes(userFeedback.location?.country)
                }

                // Find the specific feedback for this filter's question
                const matchingFeedback = userFeedback.feedbacks.find(
                    feedback => feedback.question.title === filter.title,
                )

                // If user didn't answer this question, exclude them
                if (!matchingFeedback) return false

                // Check if the user's answer matches the filter
                if (isLikert(matchingFeedback))
                    return filter.selectedValues.includes(matchingFeedback.answer.value.toString())

                if (isYesNo(matchingFeedback))
                    return filter.selectedValues.includes(matchingFeedback.answer.value.toString())

                if (isOptionsSlider(matchingFeedback) || isRadioFeedback(matchingFeedback))
                    return filter.selectedValues.includes(matchingFeedback.answer.value)

                if (isMultiSelectFeedback(matchingFeedback))
                    return matchingFeedback.answer.value.some(value => filter.selectedValues.includes(value))

                return false
            })
        })

        return adaptPersonFeedbacksToFlatFeedbacks(filteredFeedbacks)
    }, [feedbacks, flatFeedbacks, activeFilters])
}
