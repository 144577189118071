import { OptionsSliderFeedback, RadioFeedback } from '@monorepo/functions/src/types/feedback'

export const getSingleSelectChartData = (
    question: string,
    feedbacks: (RadioFeedback | OptionsSliderFeedback)[],
): { label: string; value: number; fill: string }[] => {
    const answers = feedbacks.filter(q => q.question.title === question).map(r => r.answer)
    const possibleAnswers = feedbacks[0].question.options

    return possibleAnswers.map((answer, index) => ({
        value: answers.filter(a => a.value === answer.value).length,
        label: answer.label,
        fill: `hsl(var(--chart-${index + 1}))`,
    }))
}
