import { useNavigate } from 'react-router-dom'

import { signOut } from 'firebase/auth'
import { ArrowLeft, LogOut } from 'lucide-react'

import { useAuth } from '@/shared/auth'
import { auth } from '@/shared/firebase/firebase'
import { Button } from '@/shared/ui/button'
import { SidebarTrigger } from '@/shared/ui/sidebar'

export const Header = () => {
    const navigate = useNavigate()

    const { isLoggedIn } = useAuth()

    const logout = async () => {
        await signOut(auth)
        navigate('../login')
    }

    return (
        <div className='sticky top-0 z-50 mb-5 flex items-center justify-between px-6 py-3 shadow-md backdrop-blur-md'>
            <div className='flex items-center gap-4'>
                <img className='w-80' src='/boothbits-logo.svg' alt='BoothBits logo' />
            </div>
            {isLoggedIn && (
                <div className='flex items-center gap-2'>
                    <Button variant='ghost' onClick={() => navigate('/')} className='flex items-center gap-2'>
                        <ArrowLeft className='h-4 w-4' />
                        Todos mis eventos
                    </Button>
                    <Button variant='ghost' onClick={logout} className='hidden md:block'>
                        Cerrar sesión
                    </Button>
                    <LogOut className='mr-1 block h-4 w-4 md:hidden' />
                    <SidebarTrigger />
                </div>
            )}
        </div>
    )
}
