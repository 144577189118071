import * as React from 'react'

import { PanelRightClose, PanelRightOpen } from 'lucide-react'

import { cn } from '@/shared/lib/styles'
import { useIsMobile } from '@/shared/lib/styles/useIsMobile'
import { Button } from '@/shared/ui/button'
import { Sheet, SheetContent } from '@/shared/ui/sheet'
import { TooltipProvider } from '@/shared/ui/tooltip'

import { useScreenWidth } from '../lib/styles/useScreenWidth'

const SIDEBAR_COOKIE_NAME = 'sidebar:state'
const SIDEBAR_COOKIE_MAX_AGE = 60 * 60 * 24 * 7
const SIDEBAR_WIDTH_MOBILE = '18rem'
const SIDEBAR_KEYBOARD_SHORTCUT = 'b'

type SidebarContext = {
    state: 'expanded' | 'collapsed'
    open: boolean
    setOpen: (open: boolean) => void
    openMobile: boolean
    setOpenMobile: (open: boolean) => void
    isMobile: boolean
    toggleSidebar: () => void
}

const SidebarContext = React.createContext<SidebarContext | null>(null)

function useSidebar() {
    const context = React.useContext(SidebarContext)
    if (!context) {
        throw new Error('useSidebar must be used within a SidebarProvider.')
    }

    return context
}

const SidebarProvider = React.forwardRef<
    HTMLDivElement,
    React.ComponentProps<'div'> & {
        defaultOpen?: boolean
        open?: boolean
        onOpenChange?: (open: boolean) => void
    }
>(({ defaultOpen = true, open: openProp, onOpenChange: setOpenProp, className, style, children, ...props }, ref) => {
    const isMobile = useIsMobile()
    const [openMobile, setOpenMobile] = React.useState(false)
    const screenWidth = useScreenWidth()

    // This is the internal state of the sidebar.
    // We use openProp and setOpenProp for control from outside the component.
    const [_open, _setOpen] = React.useState(defaultOpen)
    const open = openProp ?? _open
    const setOpen = React.useCallback(
        (value: boolean | ((value: boolean) => boolean)) => {
            const openState = typeof value === 'function' ? value(open) : value
            if (setOpenProp) {
                setOpenProp(openState)
            } else {
                _setOpen(openState)
            }

            // This sets the cookie to keep the sidebar state.
            document.cookie = `${SIDEBAR_COOKIE_NAME}=${openState}; path=/; max-age=${SIDEBAR_COOKIE_MAX_AGE}`
        },
        [setOpenProp, open],
    )

    // Helper to toggle the sidebar.
    const toggleSidebar = React.useCallback(() => {
        return isMobile ? setOpenMobile(open => !open) : setOpen(open => !open)
    }, [isMobile, setOpen, setOpenMobile])

    // Adds a keyboard shortcut to toggle the sidebar.
    React.useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === SIDEBAR_KEYBOARD_SHORTCUT && (event.metaKey || event.ctrlKey)) {
                event.preventDefault()
                toggleSidebar()
            }
        }

        window.addEventListener('keydown', handleKeyDown)
        return () => window.removeEventListener('keydown', handleKeyDown)
    }, [toggleSidebar])

    // We add a state so that we can do data-state="expanded" or "collapsed".
    // This makes it easier to style the sidebar with Tailwind classes.
    const state = open ? 'expanded' : 'collapsed'

    const contextValue = React.useMemo<SidebarContext>(
        () => ({
            state,
            open,
            setOpen,
            isMobile,
            openMobile,
            setOpenMobile,
            toggleSidebar,
        }),
        [state, open, setOpen, isMobile, openMobile, setOpenMobile, toggleSidebar],
    )

    return (
        <SidebarContext.Provider value={contextValue}>
            <TooltipProvider delayDuration={0}>
                <div
                    style={
                        {
                            '--sidebar-width': `${Math.round((1 / 3) * screenWidth)}px`,
                            ...style,
                        } as React.CSSProperties
                    }
                    className={cn(
                        'group/sidebar-wrapper flex min-h-svh w-full has-[[data-variant=inset]]:bg-sidebar',
                        className,
                    )}
                    ref={ref}
                    {...props}
                >
                    {children}
                </div>
            </TooltipProvider>
        </SidebarContext.Provider>
    )
})
SidebarProvider.displayName = 'SidebarProvider'

const Sidebar = React.forwardRef<HTMLDivElement, React.ComponentProps<'div'>>(
    ({ className, children, ...props }, ref) => {
        const { isMobile, state, openMobile, setOpenMobile } = useSidebar()

        if (isMobile) {
            return (
                <Sheet open={openMobile} onOpenChange={setOpenMobile} {...props}>
                    <SheetContent
                        data-sidebar='sidebar'
                        data-mobile='true'
                        className='w-[--sidebar-width] bg-sidebar p-0 text-sidebar-foreground [&>button]:hidden'
                        style={
                            {
                                '--sidebar-width': SIDEBAR_WIDTH_MOBILE,
                            } as React.CSSProperties
                        }
                    >
                        <div className='flex h-full w-full flex-col'>{children}</div>
                    </SheetContent>
                </Sheet>
            )
        }

        return (
            <div ref={ref} className='group peer hidden text-sidebar-foreground md:block' data-state={state}>
                {/* This is what handles the sidebar gap on desktop */}
                <div
                    className={cn(
                        'relative h-svh w-[--sidebar-width] bg-transparent transition-[width] duration-200 ease-linear',
                        'rotate-180 group-data-[state=collapsed]:w-0',
                    )}
                />
                <div
                    className={cn(
                        'fixed inset-y-0 z-10 hidden h-svh w-[--sidebar-width] transition-[left,right,width] duration-200 ease-linear md:flex',
                        'right-0 border-l group-data-[state=collapsed]:right-[calc(var(--sidebar-width)*-1)]',
                        className,
                    )}
                    {...props}
                >
                    <div data-sidebar='sidebar' className='flex h-full w-full flex-col bg-sidebar'>
                        {children}
                    </div>
                </div>
            </div>
        )
    },
)
Sidebar.displayName = 'Sidebar'

const SidebarTrigger = React.forwardRef<React.ElementRef<typeof Button>, React.ComponentProps<typeof Button>>(
    ({ className, onClick, ...props }, ref) => {
        const { toggleSidebar, open } = useSidebar()

        return (
            <Button
                ref={ref}
                data-sidebar='trigger'
                variant='ghost'
                size='icon'
                className={cn('h-7 w-7', className)}
                onClick={event => {
                    onClick?.(event)
                    toggleSidebar()
                }}
                {...props}
            >
                {open ? <PanelRightClose className='h-5 w-5' /> : <PanelRightOpen className='h-5 w-5' />}
                <span className='sr-only'>Toggle Sidebar</span>
            </Button>
        )
    },
)
SidebarTrigger.displayName = 'SidebarTrigger'

const SidebarContent = React.forwardRef<HTMLDivElement, React.ComponentProps<'div'>>(({ className, ...props }, ref) => {
    return (
        <div
            ref={ref}
            data-sidebar='content'
            className={cn('flex min-h-0 flex-1 flex-col gap-2 overflow-auto', className)}
            {...props}
        />
    )
})
SidebarContent.displayName = 'SidebarContent'

export { Sidebar, SidebarContent, SidebarProvider, SidebarTrigger, useSidebar }
