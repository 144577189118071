import { useEffect, useState } from 'react'

import { collection, getDoc, getDocs } from 'firebase/firestore'
import { RefreshCw } from 'lucide-react'
import { marked } from 'marked'

import { getSummariesDocRef } from '@/pages/dashboard/features/summary/SummariesModel.tsx'
import { summarizeAllFeedbacks } from '@/pages/dashboard/features/summary/SummaryApi.ts'
import { firestore } from '@/shared/firebase/firebase.ts'
import { useDocument } from '@/shared/firebase/hooks/useDocument.tsx'
import { Button } from '@/shared/ui/button.tsx'
import { useEventId } from '@/shared/useEventId.ts'
import { Summaries } from '@monorepo/functions/src/types/event'
import { Category } from '@monorepo/functions/src/types/feedback'

const useShouldShowRegenerateSummary = (eventId: string) => {
    const [shouldShow, setShouldShow] = useState(false)

    useEffect(() => {
        const fetch = async () => {
            const summariesDocRef = getSummariesDocRef(eventId)
            const summaries = (await getDoc(summariesDocRef)).data() as Summaries
            const totalFeedbacks = (await getDocs(collection(firestore, 'events', eventId, 'feedback'))).size
            const numberOfFeedbacksInSummary = summaries?.default.numberOfFeedbacks
            setShouldShow(
                // If there are no summaries, we should show the regenerate button
                !numberOfFeedbacksInSummary ||
                    // Each 10 new feedbacks we allow to regenerate
                    (numberOfFeedbacksInSummary < 100 && totalFeedbacks - numberOfFeedbacksInSummary > 10) ||
                    // 10% of new feedback we allow to regenerate
                    (numberOfFeedbacksInSummary >= 100 && numberOfFeedbacksInSummary / totalFeedbacks > 0.1),
            )
        }
        fetch()
    }, [])

    return shouldShow
}

interface Props {
    selectedCategory: Category
}

export const SummarySection = ({ selectedCategory }: Props) => {
    const eventId = useEventId()
    const [isRegenerating, setIsRegenerating] = useState(false)
    const shouldShowRegenerateSummary = useShouldShowRegenerateSummary(eventId)

    const { document: summaries } = useDocument<Summaries>({
        collectionName: `events/${eventId}/summaries`,
        id: 'default',
    })

    const getCategorySummary = (category: Category) => {
        switch (category) {
            case 'Problemas':
                return summaries?.default?.problems
            case 'Peticiones':
                return summaries?.default?.requests
            case 'Fortalezas':
                return summaries?.default?.strengths
            case 'Otros':
                return summaries?.default?.others
        }
    }

    const handleRegenerate = async () => {
        setIsRegenerating(true)
        try {
            await summarizeAllFeedbacks({ eventId })
        } finally {
            setIsRegenerating(false)
        }
    }

    return (
        <div className='mt-8 rounded-lg bg-gray-800 bg-opacity-50 p-4 shadow-xl backdrop-blur-sm'>
            <div className='mb-2 flex justify-between gap-2'>
                <h3 className='mb-2 bg-gradient-to-r from-green-400 to-emerald-600 bg-clip-text text-xl font-semibold text-transparent'>
                    Resumen de los comentarios
                </h3>
                {shouldShowRegenerateSummary && (
                    <Button
                        onClick={handleRegenerate}
                        disabled={isRegenerating}
                        variant='outline'
                        size='sm'
                        className='border-green-400 text-green-400 hover:bg-green-400 hover:text-gray-900'
                    >
                        <RefreshCw className={`mr-2 h-4 w-4 ${isRegenerating ? 'animate-spin' : ''}`} />
                        {isRegenerating ? 'Regenerando...' : 'Regenerar'}
                    </Button>
                )}
            </div>
            <div
                className='parent-ul-disc ml-4'
                dangerouslySetInnerHTML={{
                    __html: marked(getCategorySummary(selectedCategory) ?? 'No hay resumen todavía'),
                }}
            ></div>
        </div>
    )
}
